import { faPaperPlane, faRotate } from '@fortawesome/free-solid-svg-icons'
import { InvoiceLog, InvoiceLogs } from 'modules/invoice/domain/InvoiceLog'
import { HttpInvoiceRepository } from 'modules/invoice/infrastructure/HttpInvoiceRepository'
import { HttpTriggerRepository } from 'modules/trigger/infrastructure/HttpInvoiceRepository'
import { DataTablePFSEvent } from 'primereact/datatable'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Table,
  TableHandle,
  UfinetBox,
  UfinetButton,
  UfinetModal,
  UfinetSectionBox,
  UfinetSelectHandle,
  formatTableFilters,
  tableSortValueToSortDirection,
} from 'ufinet-web-components'
import {
  AuthContext,
  Authority,
  PagingData,
  defaultPagingParameters,
  useAsync,
  useModal,
  useTranslator,
} from 'ufinet-web-functions'
import { InvoiceFindResponse } from '../../modules/invoice/application/find/dto/InvoiceFindResponse'
import { mkColsInvoice } from './InvoicesColsData'
import { InvoicesFilters } from './components/InvoicesFilters'
import { InvoicesHeader } from './components/InvoicesHeader'
import { InvoiceFilters, InvoiceHeaderFilters } from './filters/InvoiceFiltersTypes'
import { InvoiceTypesFilters } from 'types/invoice/InvoiceTypes'
import { GetInvoices, UsePostSendInvoices } from 'request'
import { ErrorResponse } from 'types/invoice/InvoiceTypes'
import { useIntl } from 'react-intl'

const InvoicesPage: FC = () => {
  const intl = useIntl()
  const translate = useTranslator()
  const [filteredData, setFilteredData] = useState<any>()
  const [headerFilters, setHeaderFilters] = useState<InvoiceHeaderFilters>({})
  const [selectedValues, setSelectedValues] = useState<InvoiceLogs[]>([])
  const [paging, setPaging] = useState<any>()

  const isSelected = selectedValues && selectedValues.length > 0

  const {
    data: dataInvoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
    refetch,
    isFetching,
  } = GetInvoices(filteredData)

  const cbSuccessSendInvoices = ({ data }: any) => {
    toast.success(translate('SENDING'))
    setSelectedValues([])
    refetch()
  }

  const {
    mutate: sendInvoices,
    isError: isErrorPostInvoices,
    error: errorMessage,
  } = UsePostSendInvoices(cbSuccessSendInvoices)

  const showErrorMessagePostInvoices = () => {
    if (typeof errorMessage === 'object' && errorMessage !== null && 'errorCode' in errorMessage) {
      const errorResponse = errorMessage as ErrorResponse
      toast.error(`${translate('ERROR.MESSAGE')} ${errorResponse.errorCode}`)
    }
  }

  const showErrorMessage = () => {
    toast.error(translate('INVOICES.FETCH.ERROR'))
  }

  useEffect(() => {
    setFilteredData({
      ...filteredData,
      ...headerFilters,
    })
  }, [headerFilters])

  useEffect(() => {
    if (dataInvoices) {
      const parsePagination = JSON.parse(dataInvoices?.headers['x-pagination'])
      const formattedPagination = {
        totalElements: parsePagination.totalCount,
        totalPages: parsePagination.totalPages,
        pageSize: filteredData && filteredData.pageSize ? filteredData.pageSize : parsePagination.pageSize,
        pageNumber: parsePagination.currentPage,
      }
      setPaging(formattedPagination)
    }
  }, [dataInvoices])

  const authData = useContext(AuthContext)
  const roles = authData.userData?.authorities || []
  const permissions = Authority.getGfaConfigPermissions(roles)

  const [sendInvoicesModal, showSendInvoicesModal, hideSendInvoicesModal] = useModal()

  const tableRef = useRef<TableHandle>(null)
  const colsUser = useMemo(() => mkColsInvoice(translate), [translate])

  const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

  const invoiceRepository = useMemo(() => HttpInvoiceRepository(authData), [authData])
  const triggerRepository = useMemo(() => HttpTriggerRepository(authData), [authData])

  const selectStateRef = useRef<UfinetSelectHandle>(null)
  const selectLegalEntityRef = useRef<UfinetSelectHandle>(null)

  const getIsoDate = (date: Date | undefined) => {
    if (date) {
      return (
        date.getFullYear() +
        '-' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getDate()).padStart(2, '0') +
        'T' +
        String(date.getHours()).padStart(2, '0') +
        ':' +
        String(date.getMinutes()).padStart(2, '0') +
        ':' +
        String(date.getSeconds()).padStart(2, '0') +
        '.' +
        String(date.getMilliseconds()).padStart(3, '0') +
        'Z'
      )
    }
  }

  const onFilter = (e: DataTablePFSEvent): void => {
    const formattedFilters = formatTableFilters(e)
    setFilteredData({
      ...filteredData,
      ...headerFilters,
      ...formattedFilters,
    })
  }

  const onPage = (e: DataTablePFSEvent): void => {
    setFilteredData({
      ...filteredData,
      ...headerFilters,
      pageNumber: e.page,
      pageSize: e.rows,
    })
  }

  const onSelectionChange = useCallback((values: InvoiceLogs[]) => setSelectedValues(values), [])

  const onFilterClear = (): void => {
    setHeaderFilters({})
    setFilteredData({})
    setSelectedValues([])
    selectLegalEntityRef.current?.clearSelect()
  }

  const onSort = (e: DataTablePFSEvent): void => {
    const finalOrder = tableSortValueToSortDirection(e.sortOrder)
    setFilteredData({
      ...filteredData,
      ...headerFilters,
      sortField: e.sortField,
      sortOrder: finalOrder,
    })
  }

  const getFilterButtons = useCallback(
    () => (
      <InvoicesFilters
        headerFilters={headerFilters}
        setHeaderFilters={setHeaderFilters}
        selectLegalEntityRef={selectLegalEntityRef}
        permissions={permissions}
      />
    ),
    [headerFilters, permissions]
  )

  const postInvoices = () => {
    if (isSelected) {
      const newArray = selectedValues.map((item: InvoiceLogs) => ({
        notificationid: item.notificationid,
      }))
      sendInvoices(newArray)
    }
  }

  const getHeaderButtons = () => (
    <div>
      <UfinetButton
        id="buttonRefresh"
        icon={faRotate}
        className="me-3"
        content={translate('INVOICES.REFRESH')}
        onClick={() => refetch()}
        isDisabled={isFetching}
      />
      {permissions.canWrite && (
        <UfinetButton
          id="buttonSend"
          icon={faPaperPlane}
          content={translate('INVOICES.SEND')}
          isDisabled={selectedValues.length === 0}
          onClick={postInvoices}
        />
      )}
    </div>
  )

  return (
    <>

      {isErrorInvoices && showErrorMessage()}

      <UfinetBox>
        <UfinetSectionBox>
          <h2 className="fs-3 fw-bolder text-dark">{translate('INVOICES.TITLE')}</h2>
          <Table
            ref={tableRef}
            dataKey="invoiceReference"
            selectedValues={selectedValues}
            onSelectionChange={onSelectionChange}
            cols={colsUser}
            content={dataInvoices && dataInvoices?.data ? dataInvoices?.data?.items : []}
            filterButtons={getFilterButtons()}
            headerButtons={getHeaderButtons()}
            emptyMessage={isLoadingInvoices || isFetching ? translate('LOADING_DOTS') : undefined}
            onFilterClear={onFilterClear}
            lazySettings={
              paging && {
                ...paging,
                loading: isLoadingInvoices || isFetching,
                onFilter,
                onPage,
                onSort,
              }
            }
            enableSearchFilter={false}
            customClassFilters="col-xl-6  col-md-12 col-sm-12"
            customClassHeaders="col-xl-6 col-md-12 col-sm-12 ps-0 mt-9 pe-0"
          />
        </UfinetSectionBox>
      </UfinetBox>
    </>
  )
}
export { InvoicesPage }
